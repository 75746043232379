import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import {
  Container,
  FlexWrapper,
  H5,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { black, blue, convertHexToRGB, white } from 'styles/colors';
import { Logo, Svg } from 'components';

interface Props {
  isLoading: boolean;
  stepIndex: number;
  totalSteps: number;
  onBack: () => void;
}

export const ProgressBar: React.FC<Props> = ({
  isLoading,
  stepIndex,
  totalSteps,
  onBack,
}) => {
  const progress = useMemo(
    () => (stepIndex * 100) / totalSteps,
    [stepIndex, totalSteps],
  );

  return (
    <>
      <Wrapper>
        <ContainerStyled>
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <FlexWrapper
              id="quiz__back-button"
              data-testid="quiz__back-button"
              alignItems="center"
              onClick={onBack}
              css={css`
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
              `}
            >
              {!isLoading ? (
                <>
                  <BackIcon src="chevron_left" />
                  <H5 margin="0 0 0 0.5rem">Back</H5>
                </>
              ) : null}
            </FlexWrapper>
            <Logo />
            <Small>
              {!isLoading ? (
                <>
                  <TextWrapper
                    id="quiz__progress-index"
                    data-testid="quiz__progress-index"
                    fontWeight={700}
                  >
                    {stepIndex}
                  </TextWrapper>{' '}
                  of{' '}
                  <span
                    id="quiz__progress-total"
                    data-testid="quiz__progress-total"
                  >
                    {totalSteps}
                  </span>
                </>
              ) : null}
            </Small>
          </FlexWrapper>
        </ContainerStyled>
      </Wrapper>
      <ProgressWrapper>
        <Progress width={isLoading ? 0 : progress} />
      </ProgressWrapper>
    </>
  );
};

const Wrapper = styled(FlexWrapper).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  background-color: ${white};
  box-shadow: 0 0.5rem 1.5rem ${convertHexToRGB(black, 0.04)};
  height: 4.5rem;

  @media ${mobile} {
    height: 3.625rem;
  }
`;

const ContainerStyled = styled(Container)`
  width: 100%;
`;

const BackIcon = styled(Svg)`
  width: 1.5rem;
  height: 1.5rem;

  path {
    fill: ${blue};
  }
`;

const ProgressWrapper = styled.div`
  position: relative;
  height: 0.125rem;
  background: rgba(33, 33, 33, 0.08);
`;

const Progress = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%` || '0'};
  height: inherit;
  background: ${blue};
  transition: width 0.2s;
`;
