import React from 'react';
import styled from 'styled-components';
import {
  ContainerSmall,
  H1,
  H5,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { IQuestion, QuestionTypes } from 'state/funnel/types';
import { IQuizAnswer } from 'state/user/types';

import { GridOption } from '../options/GridOption';
import { SingleOption } from '../options/SingleOption';
import { Statement } from '../options/Statement';
import { InfoWeFeelYou } from '../options/InfoWeFeelYou';
import { InfoGreatNews } from '../options/InfoGreatNews';
import { InfoStartLookingForward } from '../options/InfoStartLookingForward';
import { InfoFounderQuote } from '../options/InfoFounderQuote';
import { InputOption } from '../options/InputOption';

export type IQuizCallbacks = {
  question: IQuestion;
  onSelect: (answer: IQuizAnswer) => void;
};

const questions: Record<
  keyof typeof QuestionTypes,
  React.FC<IQuizCallbacks>
> = {
  single: SingleOption,
  multiple: GridOption,
  statement: Statement,
  info_great_news: InfoGreatNews,
  info_we_feel_you: InfoWeFeelYou,
  info_start_looking_forward: InfoStartLookingForward,
  info_founder_quote: InfoFounderQuote,
  input: InputOption,
};

export const AskQuestion: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const { isMobile } = useQuery();
  const Question = question?.type ? questions[question.type] : null;

  const isDisplay =
    !!question.label ||
    !!question.description ||
    question?.type === 'statement';

  return (
    <>
      {isDisplay ? (
        <HeadlineWrapper textAlign={isMobile ? 'left' : 'center'}>
          {question?.type === 'statement' && (
            <H5 margin="0 0 1.5rem">
              Would you agree with the statement below?
            </H5>
          )}
          <H1 id="quiz__question-title" data-testid="quiz__question-title">
            {question.label}
          </H1>
          {question.description ? (
            <RegularStyled
              margin="0.5rem 0 0"
              dangerouslySetInnerHTML={{ __html: question.description }}
            />
          ) : null}
        </HeadlineWrapper>
      ) : null}
      {Question ? <Question onSelect={onSelect} question={question} /> : null}
    </>
  );
};

const HeadlineWrapper = styled(ContainerSmall)<{ textAlign: string }>`
  margin: 0 auto 3rem auto;
  text-align: ${({ textAlign }) => textAlign || 'inherit'};

  @media ${mobile} {
    margin: 0 0 2rem;
  }
`;

const RegularStyled = styled(Regular)`
  .spacer {
    margin: 0 0 0.5rem;
  }
`;
