import React from 'react';
import styled from 'styled-components';
import { GridWrapper } from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { IQuestionOption } from 'state/funnel/types';
import { OptionCard } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

export const SingleOption: React.FC<IQuizCallbacks> = ({
  onSelect,
  question,
}) => {
  const options = question.options || [];

  const handleSelect = (o: IQuestionOption) => {
    if (question.key) {
      onSelect({ [question.key]: o.value, label: o.label });
    }
  };

  return (
    <Wrapper>
      {(options as IQuestionOption[]).map(
        (o: IQuestionOption, index: number) => (
          <OptionCard key={index} {...o} onClick={() => handleSelect(o)} />
        ),
      )}
    </Wrapper>
  );
};

const Wrapper = styled(GridWrapper)`
  grid-gap: 0.5rem;
  max-width: 22rem;
  margin: auto;

  @media ${mobile} {
    max-width: none;
  }
`;
