import React from 'react';
import styled from 'styled-components';

import {
  ContainerSmall,
  GridWrapper,
  H2,
} from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { IQuizCallbacks } from '../sections/AskQuestion';

import { Image, OptionCard } from 'components';

export const Statement: React.FC<IQuizCallbacks> = ({ question, onSelect }) => {
  const options = question?.options ?? [];

  const handleSelect = (value: string) =>
    onSelect({ [question.key]: value, label: value });

  return (
    <ContainerSmallStyled>
      <StatementImage src={String(question.key)} />
      <H2
        id="quiz__question-title"
        data-testid="quiz__question-title"
        margin="0 0 1.5rem"
        textAlign="center"
      >
        {question.label}
      </H2>
      <Wrapper>
        {options.map((o, index) => (
          <OptionCard
            key={index}
            {...o}
            onClick={() => handleSelect(o.value)}
          />
        ))}
      </Wrapper>
    </ContainerSmallStyled>
  );
};

const ContainerSmallStyled = styled(ContainerSmall)`
  @media ${mobile} {
    padding: 0;
  }
`;

const StatementImage = styled(Image)`
  margin: 0 auto 1.5rem;
  max-width: 17.5rem;
  width: 100%;
`;

const Wrapper = styled(GridWrapper)`
  grid-gap: 0.5rem;
  max-width: 22rem;
  margin: auto;

  @media ${mobile} {
    max-width: none;
  }
`;
