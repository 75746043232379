import { AppState } from '../types';

export const quizAnswersSelector = (state: AppState) => state.user.quiz_answers;

export const userCodeSelector = (state: AppState) => state.user.code || '';

export const selectedPlanSelector = (state: AppState) =>
  state.user.selected_plan;

export const biologicalAgeSelector = (s: AppState) =>
  s.user.summary?.biological_age;

export const smokingPeriodSelector = (s: AppState) =>
  s.user.summary?.smoking_period;

export const expensesCalculationSelector = (s: AppState) =>
  s.user.summary?.expenses_calculations;

export const smokingTriggersSelector = (s: AppState) =>
  s.user.summary?.smoking_triggers;

export const conernArgumentsSelector = (s: AppState) =>
  s.user.summary?.concern_arguments;
