import React from 'react';
import styled from 'styled-components';

import {
  ContainerSmall,
  H2,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

export const InfoWeFeelYou: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const { key } = question;
  const { isMobile } = useQuery();

  return (
    <ContainerSmall>
      <Image
        maxWidth="22rem"
        src="info_we_feel_you"
        margin="2rem auto 1.5rem"
      />
      <H2
        margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
        textAlign={!isMobile && 'center'}
      >
        We feel you. It’s a hard choice to make.
      </H2>
      <RegularStyled>
        The fact that you are here right now shows that{' '}
        <TextWrapperStyled>you are at a crossroads.</TextWrapperStyled>
      </RegularStyled>
      <RegularStyled>
        You know that you might want to kick the habit. But you also don’t want
        to lose the rituals and friends you’ve made during your smoking breaks.
      </RegularStyled>
      <RegularStyled>
        <TextWrapperStyled>You don’t have to make a choice. </TextWrapperStyled>
        This is one of those rare occasions in life when you can, indeed, have
        it all.
      </RegularStyled>
      <RegularStyled>
        <TextWrapperStyled>
          Quitting by using cognitive behavioral science
        </TextWrapperStyled>{' '}
        will help you keep your routines and friendships.
      </RegularStyled>
      <PrimaryButton
        id="quiz__continue-button"
        data-testid="quiz__continue-button"
        onClick={() =>
          key && onSelect({ [key]: 'info_we_feel_you', label: 'Tell me more' })
        }
        margin="2rem auto 0"
        maxWidth={isMobile ? '100%' : '22rem'}
      >
        Tell me more
      </PrimaryButton>
    </ContainerSmall>
  );
};

const RegularStyled = styled(Regular)`
  margin: 0 0 0.5rem;
  text-align: center;

  @media ${mobile} {
    text-align: left;
  }
`;

const TextWrapperStyled = styled(TextWrapper).attrs({
  fontWeight: 'bold',
})``;
