import React from 'react';
import styled from 'styled-components';

import {
  ContainerSmall,
  H2,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

export const InfoStartLookingForward: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const { key } = question;
  const { isMobile } = useQuery();

  return (
    <ContainerSmall>
      <Image
        maxWidth="22rem"
        src="start_looking_forward"
        margin="2rem auto 1.5rem"
      />
      <H2
        margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
        textAlign={!isMobile && 'center'}
      >
        Start looking forward
      </H2>
      <RegularStyled fontWeight="bold">
        Remember, you’re not giving up anything precious by quitting. Smoking or
        vaping do absolutely nothing good for you.
      </RegularStyled>
      <RegularStyled>
        They provide you with no real pleasure and simply keep you addicted. The
        good news is that{' '}
        <TextWrapper fontWeight="bold">
          addictions can be eliminated
        </TextWrapper>
        .
      </RegularStyled>
      <RegularStyled>
        You’re going to enjoy being a non-smoker from the moment you put out
        your last cigarette.
      </RegularStyled>
      <PrimaryButton
        id="quiz__continue-button"
        data-testid="quiz__continue-button"
        onClick={() =>
          key && onSelect({ [key]: 'info_we_feel_you', label: 'Got it' })
        }
        margin="2rem auto 0"
        maxWidth={isMobile ? '100%' : '22rem'}
      >
        Got it!
      </PrimaryButton>
    </ContainerSmall>
  );
};

const RegularStyled = styled(Regular)`
  margin: 0 0 0.5rem;
  text-align: center;

  @media ${mobile} {
    text-align: left;
  }
`;
