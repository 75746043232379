import React, { useState } from 'react';
import styled from 'styled-components';

import { ContainerSmall } from '@kilornd/modules-wrapper/es/components';
import { Input, PrimaryButton } from 'components';
import { IQuizCallbacks } from '../sections/AskQuestion';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

export const InputOption: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const [value, setValue] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(String(e.target.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (value) {
      onSelect({ [question?.key]: value, label: value });
    }
    setValue(null);
  };

  return (
    <ContainerSmall>
      <Wrapper as="form" onSubmit={handleSubmit}>
        {question?.custom?.input_type === 'number' ? (
          <InputStyled
            id={`quiz__input-${question.key}`}
            dataTest={`quiz__input-${question.key}`}
            autoComplete="off"
            placeholder={question.custom?.input_placeholder}
            name={question.key}
            type={question.custom?.input_type}
            onChange={handleChange}
            min={question.custom?.min_value || 0}
            max={question.custom?.max_value || 120}
            inputMode="numeric"
            isAutoFocus
          />
        ) : (
          <Input
            id={`quiz__input-${question.key}`}
            dataTest={`quiz__input-${question.key}`}
            autoComplete="off"
            placeholder={question.custom?.input_placeholder}
            name={question.key}
            type={question.custom?.input_type}
            onChange={handleChange}
            min={0}
            max={120}
            isAutoFocus
          />
        )}
        <PrimaryButton
          id="quiz__submit-button"
          data-testid="quiz__submit-button"
          type="submit"
          disabled={!value}
          maxWidth="22rem"
          margin="1rem auto"
        >
          Continue
        </PrimaryButton>
      </Wrapper>
    </ContainerSmall>
  );
};

const Wrapper = styled.div.attrs({
  as: 'form',
})`
  max-width: 22rem;
  margin: auto;

  @media ${mobile} {
    max-width: none;
  }
`;

const InputStyled = styled(Input)`
  && ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
