import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { useLocation } from '@reach/router';

import { IQuestion } from 'state/funnel/types';
import { IQuizAnswer } from 'state/user/types';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import { Loader, Loading } from 'components';

import { questionsSelector } from 'state/funnel/selectors';
import { quizAnswersSelector } from 'state/user/selectors';

import { AskQuestion } from './sections/AskQuestion';
import { ProgressBar } from './sections/ProgressBar';

const Quiz = memo(() => {
  const dispatch = useDispatch();
  const { goToLanding, goToQuiz, goToSummary } = useRouter();
  const { search } = useLocation();
  const initialQuestions = useSelector(questionsSelector);
  const answers = useSelector(quizAnswersSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IQuestion[]>(initialQuestions);

  const params = new URLSearchParams(search);
  const questionIndex = parseFloat(params.get('question') || '1');
  const step = questionIndex - 1;
  const question = questions[step];

  const handleBackButton = () => {
    if (step > 0) {
      goToQuiz(questionIndex - 1);
    } else {
      goToLanding();
    }
  };

  const handleNextQuestions = (quizAnswers: IQuizAnswer) => {
    const answersKeys: string = Object.keys(quizAnswers).join('|');

    const availableQuestions = initialQuestions.filter((q: IQuestion) => {
      const { list, has_all_match } = q?.rules || {};
      const matchKeys = !!list?.find(rule =>
        new RegExp(answersKeys).test(rule.key),
      );

      if (list && matchKeys) {
        const checkRule = (value: string, key: string) =>
          new RegExp(value).test(String(quizAnswers[key]));

        const test = has_all_match
          ? list.every(rule => checkRule(rule.value, rule.key))
          : list.find(rule => checkRule(rule.value, rule.key));

        if (test) {
          return q;
        }
      } else if (list) {
        return;
      } else {
        return q;
      }
    });

    return availableQuestions;
  };

  const isValid = (value: IQuizAnswer) => {
    const val = Object.keys(value)?.[0];
    let isValid = false;
    if (typeof val === 'string') {
      isValid = val.length > 0;
    } else if (typeof val === 'boolean') {
      isValid = true;
    } else if (typeof val === 'number') {
      isValid = true;
    }
    return isValid;
  };

  const onSelect = (value: IQuizAnswer) => {
    Analytics.trackQuizAnswer(question['label'], String(value['label']));

    delete value['label'];

    if (isValid(value)) {
      dispatch(updateQuizAnswers(value));

      const availableQuestions = handleNextQuestions({ ...answers, ...value });

      setQuestions(availableQuestions);

      if (step + 1 < availableQuestions.length) {
        setTimeout(() => {
          goToQuiz(questionIndex + 1);
        }, 200);

        window.scrollTo(0, 0);
      } else {
        setIsLoading(true);

        setTimeout(() => {
          goToSummary();
        }, 6500);
      }
    }
  };

  useEffect(() => {
    const availableQuestions = handleNextQuestions(answers);
    setQuestions(availableQuestions);
  }, [initialQuestions]);

  if (isLoading) {
    return (
      <>
        <ProgressBar
          isLoading={isLoading}
          stepIndex={step + 1}
          totalSteps={questions.length}
          onBack={handleBackButton}
        />
        <Loading />
      </>
    );
  }

  return !question ? (
    <Loader />
  ) : (
    <>
      <ProgressBar
        isLoading={isLoading}
        stepIndex={step + 1}
        totalSteps={questions.length}
        onBack={handleBackButton}
      />
      <ContainerWrapper>
        <AskQuestion question={question} onSelect={onSelect} />
      </ContainerWrapper>
    </>
  );
});

Quiz.displayName = 'Quiz';

export default Quiz;

const ContainerWrapper = styled.div`
  padding: 3rem 1rem;

  @media ${mobile} {
    padding: 1rem 1rem 4rem 1rem;
  }
`;
