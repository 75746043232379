import React from 'react';

import { ContainerSmall, Large } from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

export const InfoFounderQuote: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const { key } = question;
  const { isMobile } = useQuery();

  return (
    <ContainerSmall>
      <Image maxWidth="6.5rem" src="quotes" margin="0 0 1.5rem" />
      <Large margin="0 0 0.5rem" textAlign={!isMobile && 'center'}>
        The day my son was born, I became not only a father but also an example.
        I didn’t want my bad habits to become a part of his new life. Using
        cognitive behavioral therapy principles, I’ve created a program
        addressing all the situations that might occur while you quit smoking.
        The plan allowed me to quit without any withdrawal symptoms, and I
        wanted to share the lessons I’ve learned with you.
      </Large>
      <Large fontWeight={700} textAlign="right">
        {isMobile ? (
          <em>James, Founder of ZeroSmokes</em>
        ) : (
          'James, Founder of ZeroSmokes'
        )}
      </Large>
      <PrimaryButton
        id="quiz__continue-button"
        data-testid="quiz__continue-button"
        onClick={() =>
          key && onSelect({ [key]: 'info_founder_quote', label: 'Get my plan' })
        }
        margin="2rem auto 0"
        maxWidth={isMobile ? '100%' : '22rem'}
      >
        Get my plan
      </PrimaryButton>
    </ContainerSmall>
  );
};
