import React, { useEffect, useState } from 'react';
import {
  mobile,
  useQuery,
  xsmTablet,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { GridWrapper } from '@kilornd/modules-wrapper/es/components';
import styled from 'styled-components';

import { IQuestionOption } from 'state/funnel/types';
import { Image, OptionCard, PrimaryButton } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

interface IData {
  [key: string]: string | boolean;
}

export const GridOption: React.FC<IQuizCallbacks> = ({
  onSelect,
  question,
}) => {
  const { isMobile, isXsmTablet } = useQuery();
  const { options, image, imageMobile } = question;
  const deselector = options.find(
    (o: IQuestionOption) => o.custom?.is_deselect_all,
  )?.value;
  const limit = question?.custom?.limit;
  const [selected, setSelected] = useState<IData>({});
  const disabled = Object.keys(selected).length === 0;
  const hasTwoColumns = options.length >= 6 && options.length <= 16;
  const hasThreeColumns = options.length > 16;

  const handleOptionClick = (option: IQuestionOption) => () => {
    let newSelected: IData = { ...selected };
    if (selected[String(option.value)]) {
      delete newSelected[String(option.value)];
    } else if (option?.custom?.is_deselect_all) {
      newSelected = { [String(option.value)]: true };
    } else if (limit === undefined || limit > Object.keys(selected).length) {
      newSelected = { ...selected, [String(option.value)]: true };
      delete newSelected[String(deselector)];
    }
    setSelected(newSelected);
  };

  const onSubmit = () => {
    if (!disabled) {
      const answer = Object.keys(selected).join('|');

      if (question.key) {
        onSelect({ [question.key]: answer, label: answer });
      }
    }
  };

  useEffect(() => {
    setSelected({});
  }, [question]);

  return (
    <Wrapper
      maxWidth={hasThreeColumns ? '70rem' : hasTwoColumns ? '45rem' : '22rem'}
      margin={(!image || isXsmTablet) && '0 auto'}
    >
      <GridWrapperStyled
        columns={
          hasThreeColumns && !isMobile ? 3 : hasTwoColumns && !isMobile ? 2 : 1
        }
        gridGap="0.5rem 2rem"
        margin={isMobile ? '0 0 1rem' : '0 0 2rem'}
      >
        {(options as IQuestionOption[]).map(
          (o: IQuestionOption, index: number) => (
            <OptionCard
              key={index}
              {...o}
              isSelectable
              isSelected={!!selected[String(o.value)]}
              onClick={handleOptionClick(o)}
            />
          ),
        )}
      </GridWrapperStyled>
      {!!image && !!imageMobile && (
        <ImageStyled src={isMobile ? imageMobile : image} />
      )}
      <PrimaryButtonStyled
        id="quiz__continue-button"
        data-testid="quiz__continue-button"
        disabled={disabled}
        onClick={onSubmit}
        maxWidth={isMobile ? '100%' : '22rem'}
        margin="0 auto"
      >
        Continue
      </PrimaryButtonStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  maxWidth: string;
  margin: string | false;
}>`
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin || 0};
  z-index: 1;

  @media ${mobile} {
    max-width: none;
  }
`;

const ImageStyled = styled(Image)`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 22rem;
  z-index: 1;

  @media ${xsmTablet} {
    position: relative;
    margin: 0 0 1rem;
  }
`;

const GridWrapperStyled = styled(GridWrapper)`
  position: relative;
  z-index: 2;
`;

const PrimaryButtonStyled = styled(PrimaryButton)`
  position: relative;
  z-index: 2;
`;
