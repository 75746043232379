import React from 'react';
import styled from 'styled-components';

import {
  ContainerSmall,
  ContentFrameSmall,
  H2,
  Large,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton } from 'components';

import { IQuizCallbacks } from '../sections/AskQuestion';

export const InfoGreatNews: React.FC<IQuizCallbacks> = ({
  question,
  onSelect,
}) => {
  const { key } = question;
  const { isMobile } = useQuery();

  return (
    <ContainerSmall>
      <Image maxWidth="22rem" src="info_great_news" margin="2rem auto 1.5rem" />
      <H2
        margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
        textAlign={!isMobile && 'center'}
      >
        Great news! We know exactly how to help you.
      </H2>
      <RegularStyled>
        <TextWrapper fontWeight="bold">
          Motivation is the first step
        </TextWrapper>{' '}
        to a successful habit change.
      </RegularStyled>
      <RegularStyled>
        We have already compiled a{' '}
        <TextWrapper fontWeight="bold">list of the next steps</TextWrapper> that
        will help you quit for good.
      </RegularStyled>
      <RegularStyled>
        The sooner you set the process in motion, the higher your success rate
        will be.
      </RegularStyled>
      <PrimaryButton
        id="quiz__continue-button"
        data-testid="quiz__continue-button"
        onClick={() =>
          key && onSelect({ [key]: 'info_great_news', label: 'lets_do_it' })
        }
        margin="2rem auto 0"
        maxWidth={isMobile ? '100%' : '22rem'}
      >
        Let's do it!
      </PrimaryButton>
    </ContainerSmall>
  );
};

const RegularStyled = styled(Regular)`
  margin: 0 0 0.5rem;
  text-align: center;

  @media ${mobile} {
    text-align: left;
  }
`;
